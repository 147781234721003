import activeClasses from "./activeClasses"
import inActiveClasses from "./inactiveClasses"

/**
 * @param {HTMElement} el
 */
export default function toggleClasses(el, active = true) {
    if (!active) {
        el.classList.remove(...inActiveClasses(el))
        el.classList.add(...activeClasses(el))
    } else {
        el.classList.remove(...activeClasses(el))
        el.classList.add(...inActiveClasses(el))
    }
}
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import logo from "../svg/logo_white.svg"
import LogoMobile from "../svg/logo_mobile.svg"
import MobileNavigation from "./common/MobileNavigation.js"
import User from "../svg/user.svg"
import UserWhite from "../svg/user-white.svg"
import MenuMobile from "../svg/menu-mobile.svg"

const isBrowser = typeof window !== "undefined"
function Header(props) {
  const [menu, setMenu] = useState([])

  useEffect(() => {
    if (isBrowser) {
      async function init() {
        const data = await localStorage.getItem("headerMenu")
        setMenu(JSON.parse(data))
      }
      init()
    }
    const headerElement = document.querySelector(".js-main-navigation")
    if (headerElement && typeof MobileNavigation !== undefined) {
      const header = new MobileNavigation(
        headerElement,
        document.querySelector("html")
      )
      header.init()
    }
  }, [])

  return (
    <div className="relative main-container">
      <div className={props.class}>
        <div className="bg-blue-400 shadow-lg">
          <nav className="bg-blue-400 lg:bg-transparent shadow-lg lg:shadow-none js-main-navigation container relative flex items-center justify-between flex-wrap py-5 px-7 md:px-6 z-50">
            <div className="flex items-center flex-shrink-0 text-white mr-2 xl:mr-6 z-10">
              <Link to="/">
                <img
                  className="w-40 h-auto mb-0 hidden lg:block"
                  src={logo}
                  alt="Manus"
                ></img>
                <img
                  className="w-10 h-auto mb-0 lg:hidden"
                  src={LogoMobile}
                  alt="Manus"
                ></img>
              </Link>
            </div>
            <div className="block lg:hidden">
              <Link
                to="#"
                className="js-mobile-nav-toggle flex items-center px-3 py-2 rounded text-white"
              >
                <img src={MenuMobile} className="mb-0" alt="Manus" />
              </Link>
            </div>
            <div
              className="js-menu w-full hidden lg:flex absolute lg:relative top-full left-0 py-2 flex-grow z-10 lg:items-center lg:w-auto"
              data-active-classes="block lg:h-auto bg-blue-400 lg:bg-transparent"
              data-inactive-classes="hidden"
            >
              <div className="text-md lg:flex-grow lg:flex lg:justify-end items-center">
                {menu.map((link, index, arr) => {
                  return arr.length - 1 === index ? (
                    <Link
                      to={link.url}
                      key={link.url}
                      className="menu-item cta-header flex w-max mt-5 lg:mt-0 text-xs text-blue-400 hover:text-white hover:bg-gray-300 bg-white px-5 py-3 rounded-full font-medium mb-2 lg:mb-0 items-center mx-7 lg:mx-0"
                    >
                      <img
                        src={User}
                        className="w-6 h-6 mb-0 mr-3"
                        alt="user"
                      />
                      <img
                        src={UserWhite}
                        className="hidden w-6 h-6 mb-0 mr-3"
                        alt="user"
                      />
                      {link.label}
                    </Link>
                  ) : (
                    <Link
                      to={link.url}
                      key={link.url}
                      className="menu-item link-header px-7 lg:px-0 menu-item block lg:inline-block mt-1 lg:mt-0 text-base text-white lg:mr-10 mb-2 lg:mb-0 py-1 lg:py-0"
                    >
                      {link.label}
                    </Link>
                  )
                })}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Header

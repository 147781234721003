import toggleClasses from '../../utils/toggleClasses';

class MobileNav {
    constructor(el, doc) {
        this.el = el;
        this.doc = doc;
        this.menu = this.el.querySelector('.js-menu');
        this.menuItems = this.el.querySelectorAll('.menu-item');
        this.toggleTarget = this.el.querySelector('.js-mobile-nav-toggle');
        this.opened = false;
    }

    init() {
        if(this.toggleTarget && this.menu) {
            this.toggleTarget.addEventListener('click', this.onClick.bind(this));
        }
        this.menuItems.forEach( item => {
            item.addEventListener('click', this.onClick.bind(this));
        })
    }

    onClick() {
        toggleClasses(this.menu, this.opened);
        this.opened = !this.opened;
    }
}

export default MobileNav;